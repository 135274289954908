import {
	isDefined
} from '../../common/js/utilities.js';

class CourseTimer {
	constructor(){
		// Check if the user has the courseTimer activated
		// and if this is an LD post
		if ( this.isCourseTimerEnabled() && this.isLearnDashPost() ){
			// Set settings
			this.setSettings();

			// Get elements
			this.getElements();

			// Display the live timers
			this.timerInterval = null;
			this.initLiveTimers();
			this.handleIdleEvents();

			try {
				// Listen activity inside iframes
				this.listenActivityInsideIframePageLoad();
			}
			catch ( e ){ console.log( e ); }

			try {
				// Detect if a new iframe is added to the page
				this.listenNewIframes();
			}
			catch ( e ){ console.log( e ); }
		}	
	}

	getElements(){
		this.$elements = {
			iframes: document.querySelectorAll( '.learndash iframe' ),
			liveTimers: document.querySelectorAll( '.uotp-time-live' )
		}
	}

	setSettings(){
		this.settings = {
			events: [
				'mousedown',
				'mousemove',
				'keypress',
				'scroll',
				'touchstart',
				'wheel'
			],
			eventSettings: {
				bubbles:    true,
				cancelable: false,
				detail:     null
			}
		}
	}

	listenActivityInsideIframePageLoad(){
		// Iterate all iframes
		this.$elements.iframes.forEach(( iframe ) => {
			// Listen activity inside each iframe
			this.listenActivityInIframe( iframe );
		});
	}

	listenActivityInIframe( iframe ){
		try {
			// Check if the iframe loaded
			$( iframe ).on( 'load', () => {
				// Iterate the all the events
				this.settings.events.forEach(( eventName ) => {
					// Add event listeners by iframe
					try {
						iframe.contentWindow.addEventListener(
							eventName,
							event => this.triggerEvent( iframe, eventName )
						);
					}
					catch ( e ){}
				});
			});
		}
		catch ( e ){ console.log( e ); }
			
	}

	listenNewIframes(){
		// Create the Mutation Observer
		var observer = new MutationObserver(( mutations ) => {
			// Iterate the mutations
			mutations.forEach(( mutation ) => {
				// Check if nodes are added
				if ( mutation.addedNodes.length > 0 ){
					mutation.addedNodes.forEach((node) => {
						// Check if the added node is an iframe
						if ( node.nodeName === 'IFRAME' ){
							// Listen activity inside the iframe
							this.listenActivityInIframe( node );
						}
					});
				}
			});
    	});

		// Observer the mark as complete buttons
		observer.observe( document.body, {
			childList: true,
			subtree:   true,
			attributeFilter: [ 'disabled' ]
		});
	}

	triggerEvent( iframe, eventName ){
		// Create the event
		var event = document.createEvent( 'CustomEvent' );
		event.initCustomEvent(
			eventName,
			this.settings.eventSettings.bubbles,
			this.settings.eventSettings.cancelable,
			this.settings.eventSettings.detail
		);

		// Dispatch the event
		iframe.dispatchEvent( event );
	}

	initLiveTimers(){
		// Iterate all the live timers
		this.$elements.liveTimers.forEach(( timer ) => {
			const $timer = $( timer );
			// Time is the text inside the element in seconds 00:00:00
			let time = this.timeToSeconds($timer.text());
			// Start the timer
			this.timerInterval = setInterval(() => {
				time++;
				$timer.text(this.secondsToTime(time));
			}, 1000);
		});
	}

	handleIdleEvents() {
		$(document).on("idle.idleTimer", () => {
			// Pause the timer
			clearInterval(this.timerInterval);
		});
		
		$(document).on("active.idleTimer", () => {
			// Restart the timer
			this.initLiveTimers();
		});
	}

	// Convert time in HH:MM:SS format to seconds
	timeToSeconds(time) {
		const [hours, minutes, seconds] = time.split(':').map(Number);
		return hours * 3600 + minutes * 60 + seconds;
	}

	// Convert seconds to time in HH:MM:SS format
	secondsToTime(seconds) {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const remainingSeconds = seconds % 60;
		return [hours, minutes, remainingSeconds].map(v => v < 10 ? '0' + v : v).join(':');
	}

	isCourseTimerEnabled(){
		return isDefined( window.uoTimer );
	}

	isLearnDashPost(){
		return isDefined( document.querySelector( '.learndash' ) );
	}
}

export default CourseTimer;