import './scss/main.scss';

import CourseDashboard      from './js/course-dashboard.js';
import CourseTimer          from './js/course-timer.js';
import LazyCourseNavigation from './js/lazy-course-navigation.js';
import DownloadCertificates from './js/download-certificates.js';

// Do on DOM ready
document.addEventListener( 'DOMContentLoaded', () => {
	new CourseDashboard();
	new CourseTimer();
	new LazyCourseNavigation();
	new DownloadCertificates();
});
