import {
	restCall,
	isDefined
} from '../../common/js/utilities.js';

class LazyCourseNavigation {
	constructor(){
		// Get elements
		this.getElements();

		// Check if we have to execute the code
		if ( this.isLazyCourseNavigation() ){
			// Get course navigation
			this.getCourseNavigation(( courseNavigation ) => {
				// Set course navigation
				this.setCourseNavigation( courseNavigation );
			});
		}
	}

	getElements(){
		// Create elements property
		this.$elements = {
			container: $( '.ultp-lazy-course-navigation' )
		}
	}

	getCourseNavigation( callback ){
		// Do AJAX request
		restCall( 'course_navigation', {
			course_id: UncannyToolkitProLazyCourseNavigation.course_id,
            lesson_id: UncannyToolkitProLazyCourseNavigation.lesson_id,
            topic_id: UncannyToolkitProLazyCourseNavigation.topic_id,
            nonce: UncannyToolkitProLazyCourseNavigation.nonce
		}, ( response ) => {
			// On success
			callback( response );
		}, () => {
			// On fail
			console.error( `We couldn't load the course navigation content.` );
		});
	}

	setCourseNavigation( courseNavigation ){
		// Remove loading class
		this.$elements.container.removeClass( 'ultp-lazy-course-navigation--loading' );

		// Set the content in each container
		$.each( this.$elements.container, ( index, container ) => {
			// Get jQuery DOM element
			const $container = $( container );

			// Set the content
			$container.html( courseNavigation.html );
		});
	}

	isLazyCourseNavigation(){
		return this.$elements.container.length > 0 && isDefined( UncannyToolkitProLazyCourseNavigation );
	}
}

export default LazyCourseNavigation;